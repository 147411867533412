import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";
import "../App.css";

import Cookies from "universal-cookie";
import Loading from "./Loading";
import PageError from "./Error";
import Tracking from "./Tracking";
import ReactPaginate from "react-paginate";

const cookies = new Cookies();
const tracking = new Tracking();
const apiUrl = process.env.REACT_APP_API_SOURCE;
const extra = process.env.REACT_APP_EXTRA;

export default function Articulos() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ordered, setOrdered] = useState([]);
  const [token, setToken] = useState(null);
  const [form, setForm] = useState({ search: "", orderBy: "", categoryBy: "" });
  const [modalActualizar, setModalActualizar] = useState(false);
  const [modalNuevo, setModalNuevo] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(16);

  const [row, setRow] = useState({
    id: "",
    name: "",
    description: "",
    category: "",
    price: 0,
    image: "",
    images: [],
  });

  useEffect(() => {
    async function getToken() {
      const url = apiUrl + "app";
      await axios({
        method: "POST",
        url: url,
        headers: {
          Extra: `${extra}`,
        },
      }).then((res) => {
        setToken(res.data.token);
        window.token = res.data.token;
      });
    }

    async function getProducts() {
      if (token) {
        let url = apiUrl + "Products/1/";
        await axios({
          method: "POST",
          url: url,
          headers: {
            "x-access-token": `${token}`,
          },
          params: {
            search: form.search,
            orderBy: form.orderBy,
            categoryBy: form.categoryBy,
          },
        }).then(
          (result) => {
            setProducts(result.data.data);
            setLimit(result.data.limit);
            setPageCount(Math.ceil(result.data.total / limit));
          },
          (err) => {
            setError(err);
          }
        );
      }
    }

    async function getCategories() {
      if (token) {
        let url = apiUrl + "Categories";

        axios({
          method: "GET",
          url: url,
          headers: {
            "x-access-token": `${token}`,
          },
        }).then(
          (result) => {
            setCategories(result.data);
          },
          (err) => {
            setError(err);
          }
        );
      }
    }

    if (!cookies.get("username")) {
      window.location.href = "./login";
    } else {
      if (!token) {
        (async () => {
          await getToken();
          await getCategories();
          await getProducts();
          setLoading(false);
        })();
      } else {
        getCategories();
        getProducts();
        setLoading(false);
      }

      tracking.TrackingAdmin();
    }
  }, [token, limit, form]);

  useEffect(() => {
    let filter = {};
    if ((form.categoryBy.length === 0) | (form.categoryBy === "Todos")) {
      filter = products.filter(
        (product) =>
          product.name.toLowerCase().includes(form.search.toLowerCase()) ||
          product.description.toLowerCase().includes(form.search.toLowerCase())
      );
    } else {
      filter = products.filter((product) =>
        product.category.toLowerCase().includes(form.categoryBy.toLowerCase())
      );
      filter = filter.filter(
        (product) =>
          product.name.toLowerCase().includes(form.search.toLowerCase()) ||
          product.description.toLowerCase().includes(form.search.toLowerCase())
      );
    }

    switch (form.orderBy) {
      case "price":
        setOrdered(filter.sort((a, b) => (a.price > b.price ? 1 : -1)));
        break;

      case "name":
        setOrdered(filter.sort((a, b) => (a.name > b.name ? 1 : -1)));
        break;

      case "category":
        setOrdered(filter.sort((a, b) => (a.category > b.category ? 1 : -1)));
        break;

      default:
        setOrdered(filter.sort((a, b) => (a.category > b.category ? 1 : -1)));
        break;
    }
  }, [form, products]);

  function handleChangeForm(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  function handleChangeRow(e) {
    setRow({ ...row, [e.target.name]: e.target.value });
  }

  function mostrarModalActualizar(dato) {
    setRow(dato);
    setModalActualizar(true);
  }

  function mostrarModalNuevo() {
    setModalNuevo(true);
  }

  function mostrarModalEliminar(dato) {
    setRow(dato);
    setModalEliminar(true);
  }

  async function handleChangeImage(e) {
    if (e.target.files[0].name) {
      let filename = await uploadImage(e.target.files[0]);
      setRow({ ...row, image: filename });
    }
  }

  async function uploadImage(image) {
    let filename = "";
    let body = new FormData();
    body.set("key", "09b15ded7c0bc46427914b61799dee39");
    body.append("image", image);

    await axios({
      method: "post",
      url: "https://api.imgbb.com/1/upload",
      data: body,
    }).then((res) => {
      //console.log("Response from main API: ", res);
      filename = res.data.data.url;
    });
    return filename;
  }

  async function eliminarImagenHandler(productoId, index) {
    const array = row.images;
    const indexToRemove = array.indexOf(index);

    array.splice(indexToRemove, 1);

    setRow({ ...row, images: array });

    let url =
      process.env.REACT_APP_API_SOURCE + "deleteMoreImage/" + productoId;
    await axios({
      method: "PUT",
      url: url,
      data: {
        item: index,
      },
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {})
      .catch((err) => console.log(err));
  }

  async function onChangeImages(e) {
    if (e.target.files[0].name) {
      let filename = await uploadImage(e.target.files[0]);
      let array = row.images.concat(filename);

      setRow({
        ...row,
        images: array,
      });
    }
  }

  async function uploadMoreImageHandler(productoId) {
    if (row.image) {
      let filename = await uploadImage(row.image);
      let url = process.env.REACT_APP_API_SOURCE + "moreImage/" + productoId;
      await axios({
        method: "PUT",
        url: url,
        data: {
          images: filename,
        },
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {})
        .catch((err) => console.log(err));
    }

    setRow({
      ...row,
      image: "",
    });
  }

  function editar(dato) {
    var arreglo = products;
    let url = apiUrl + "Products/" + dato._id;

    axios({
      method: "PUT",
      url: url,
      data: dato,
      headers: {
        "x-access-token": `${token}`,
      },
    })
      .then((res) => {
        //console.log(res);
        window.location = "/list";
      })
      .catch((err) => console.log(err));
    setProducts(arreglo);
  }

  function nuevo(dato) {
    var arreglo = products;
    let url = apiUrl + "Products/";

    axios({
      method: "POST",
      url: url,
      data: dato,
      headers: {
        "x-access-token": `${token}`,
      },
    })
      .then((res) => {
        //console.log(res);
        window.location = "/list";
      })
      .catch((err) => console.log(err));
    setProducts(arreglo);
  }

  function eliminar(dato) {
    var arreglo = products;

    let url = apiUrl + "Products/" + dato._id;

    axios({
      method: "DELETE",
      url: url,
      headers: {
        "x-access-token": `${token}`,
      },
    })
      .then((res) => {
        window.location = "/list";
      })
      .catch((err) => console.log(err));

    setProducts(arreglo);
  }

  function cerrarModalActualizar() {
    setModalActualizar(false);
  }

  function cerrarModalNuevo() {
    setModalNuevo(false);
  }

  function cerrarModalEliminar() {
    setModalEliminar(false);
  }

  const fetchProducts = async (currentPage) => {
    if (token) {
      let url = apiUrl + `Products/${currentPage}/`;
      await axios({
        method: "POST",
        url: url,
        headers: {
          "x-access-token": `${token}`,
        },
        params: {
          search: form.search,
          orderBy: form.orderBy,
          categoryBy: form.categoryBy,
        },
      }).then(
        (result) => {
          setProducts(result.data.data);
          setLimit(result.data.limit);
          setPageCount(Math.ceil(result.data.total / limit));
        },
        (err) => {
          setError(err);
        }
      );
    }
  };

  async function handlePageClick(data) {
    let currentPage = data.selected + 1;
    await fetchProducts(currentPage);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <React.Fragment>
      {loading ? <Loading /> : null}
      {error ? <PageError error={error} /> : null}
      <Container>
        <br />

        <div className="flex-row-container">
          <div className="flex-row-item">
            <Button
              className="btn btn-success"
              onClick={() => mostrarModalNuevo()}
            >
              Crear
            </Button>
          </div>
          <div className="container">
            <div className="products" style={{ marginTop: "10px" }}></div>
            <div className="flex-row-container">
              <div className="flex-row-item">
                <label htmlFor="categoryBy">
                  <i className="fa fa-fw fa-list"></i>
                </label>
                <select
                  name="categoryBy"
                  id="categoryBy"
                  placeholder="Seleccionar"
                  onChange={(e) => handleChangeForm(e)}
                >
                  {categories.map((item) => (
                    <option key={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="flex-row-item">
                <label htmlFor="orderBy">
                  <i className="fa fa-fw fa-sort"></i>
                </label>
                <select
                  name="orderBy"
                  id="orderBy"
                  placeholder="Seleccionar"
                  onChange={(e) => handleChangeForm(e)}
                >
                  <option value="category">Categoría</option>
                  <option value="price">Precio</option>
                  <option value="name">Nombre</option>
                </select>
              </div>
              <div className="flex-row-item">
                <i className="fa fa-fw fa-search"></i>
                <input
                  className="searchInput"
                  type="text"
                  placeholder="Buscar"
                  name="search"
                  onChange={(e) => handleChangeForm(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <br />

        <Table>
          <thead>
            <tr>
              <th></th>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Categoría</th>
              <th>Precio</th>
              <th>Imágen</th>
              <th>Acciones</th>
            </tr>
          </thead>

          <tbody>
            {ordered.map((producto) => (
              <tr key={producto._id}>
                <td></td>
                <td>{producto.name}</td>
                <td>
                  {producto.description}
                  <br />
                </td>
                <td>{producto.category}</td>
                <td>${producto.price}.00</td>
                <td>
                  <img
                    src={producto.image}
                    width="50px"
                    height="50px"
                    alt="Not found"
                  />
                </td>
                <td>
                  <div className="flex-row-container">
                    <div className="flex-row-item">
                      <Button
                        className="btn btn-primary"
                        onClick={() => mostrarModalActualizar(producto)}
                      >
                        <i className="fa fa-pen"></i>
                      </Button>
                    </div>
                    <div className="flex-row-item">
                      <Button
                        className="btn btn-danger"
                        onClick={() => mostrarModalEliminar(producto)}
                      >
                        <i className="fa fa-window-close"></i>
                      </Button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={3}
          pageRangeDisplayed={3}
          onPageChange={(e) => handlePageClick(e)}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </Container>

      <Modal isOpen={modalActualizar} size="lg">
        <ModalHeader>
          <div className="products" style={{ marginTop: "30px" }}></div>
          <div>
            <h3>Editar Registro</h3>
          </div>
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <div
              className="container"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <img src={row.image} alt="Not found" />
            </div>
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => handleChangeImage(e)}
            />
          </FormGroup>

          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "stretch" }}
          >
            <FormGroup style={{ flexBasis: "30%" }}>
              <label>Id:</label>
              <input
                className="form-control"
                readOnly
                name="_id"
                type="text"
                value={row._id}
              />
            </FormGroup>

            <FormGroup style={{ flexBasis: "70%" }}>
              <label>Nombre:</label>
              <input
                required
                className="form-control"
                name="name"
                type="text"
                onChange={(e) => handleChangeRow(e)}
                value={row.name}
              />
            </FormGroup>
          </div>

          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "stretch" }}
          >
            <FormGroup style={{ flexBasis: "50%" }}>
              <label>Descripción:</label>
              <input
                required
                className="form-control"
                name="description"
                type="text"
                onChange={(e) => handleChangeRow(e)}
                value={row.description}
              />
            </FormGroup>

            <FormGroup style={{ flexBasis: "30%" }}>
              <label>Categoría:</label>
              <input
                required
                className="form-control"
                name="category"
                type="text"
                onChange={(e) => handleChangeRow(e)}
                value={row.category}
              />
            </FormGroup>

            <FormGroup style={{ flexBasis: "20%" }}>
              <label>Precio:</label>
              <input
                required
                className="form-control"
                name="price"
                type="number"
                onChange={(e) => handleChangeRow(e)}
                value={row.price}
              />
            </FormGroup>
          </div>

          <FormGroup>
            <label>Más imágenes:</label>

            <div
              className="container"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {row.images.map((el, index) => (
                <div key={index}>
                  <img
                    src={el}
                    key={index}
                    height="50px"
                    alt="Not found"
                    onClick={() => mostrarModalActualizar(row)}
                  />
                  <div className="card-body">
                    <button
                      onClick={() => eliminarImagenHandler(row._id, el)}
                      className="btn btn-danger"
                    >
                      <i className="fa fa-window-close"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div
              className="container mt-2"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <input
                type="file"
                id="image"
                accept="image/png, image/jpeg"
                className="form-control col-md-10"
                onChange={(e) => onChangeImages(e)}
              />
              <Button
                className="btn btn-primary col-md-2"
                onClick={() => uploadMoreImageHandler(row._id)}
              >
                <i className="fa fa-upload"></i>
              </Button>
            </div>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => editar(row)}>
            Aplicar
          </Button>
          <Button color="danger" onClick={() => cerrarModalActualizar()}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalNuevo} size="lg">
        <ModalHeader>
          <div className="products" style={{ marginTop: "30px" }}></div>
          <div>
            <h3>Crear Registro</h3>
          </div>
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <div
              className="container"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <img src={row.image} alt="Not found" />
            </div>
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => handleChangeImage(e)}
            />
          </FormGroup>

          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "stretch" }}
          >
            <FormGroup style={{ flexBasis: "30%" }}>
              <label>Id:</label>
              <input
                className="form-control"
                readOnly
                name="_id"
                type="text"
                value={row._id}
              />
            </FormGroup>

            <FormGroup style={{ flexBasis: "70%" }}>
              <label>Nombre:</label>
              <input
                required
                className="form-control"
                name="name"
                type="text"
                onChange={(e) => handleChangeRow(e)}
                value={row.name}
              />
            </FormGroup>
          </div>

          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "stretch" }}
          >
            <FormGroup style={{ flexBasis: "50%" }}>
              <label>Descripción:</label>
              <input
                required
                className="form-control"
                name="description"
                type="text"
                onChange={(e) => handleChangeRow(e)}
                value={row.description}
              />
            </FormGroup>

            <FormGroup style={{ flexBasis: "30%" }}>
              <label>Categoría:</label>
              <input
                required
                className="form-control"
                name="category"
                type="text"
                onChange={(e) => handleChangeRow(e)}
                value={row.category}
              />
            </FormGroup>

            <FormGroup style={{ flexBasis: "20%" }}>
              <label>Precio:</label>
              <input
                required
                className="form-control"
                name="price"
                type="number"
                onChange={(e) => handleChangeRow(e)}
                value={row.price}
              />
            </FormGroup>
          </div>

          <FormGroup>
            <label>Más imágenes:</label>

            <div
              className="container"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {row.images.map((el, index) => (
                <div key={index}>
                  <img
                    src={el}
                    key={index}
                    height="50px"
                    alt="Not found"
                    onClick={() => mostrarModalActualizar(row)}
                  />
                  <div className="card-body">
                    <button
                      onClick={() => eliminarImagenHandler(row._id, el)}
                      className="btn btn-danger"
                    >
                      <i className="fa fa-window-close"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div
              className="container mt-2"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <input
                type="file"
                id="image"
                accept="image/png, image/jpeg"
                className="form-control col-md-10"
                onChange={(e) => onChangeImages(e)}
              />
              <Button
                className="btn btn-primary col-md-2"
                onClick={() => uploadMoreImageHandler(row._id)}
              >
                <i className="fa fa-upload"></i>
              </Button>
            </div>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => nuevo(row)}>
            Aplicar
          </Button>
          <Button color="danger" onClick={() => cerrarModalNuevo()}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEliminar} size="lg">
        <ModalHeader>
          <div className="products" style={{ marginTop: "30px" }}></div>
          <div>
            <h3>¿Desea eliminar el Registro?</h3>
          </div>
        </ModalHeader>

        <ModalBody>
          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "stretch" }}
          >
            <FormGroup style={{ flexBasis: "100%" }}>
              <input
                className="form-control"
                name="name"
                type="text"
                defaultValue={row.name}
                onClick={(e) => onChangeImages(e)}
              />
            </FormGroup>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => eliminar(row)}>
            Aplicar
          </Button>
          <Button color="danger" onClick={() => cerrarModalEliminar()}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
