import React, { Component } from "react";

export default class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="notFound"></div>
      </React.Fragment>
    );
  }
}
