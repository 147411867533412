import React from 'react';
// import {Helmet} from "react-helmet";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";

import Products from "./components/Products"
import CreateProducts from "./components/CreateProducts";
import Login from './components/Login';
import List from './components/list';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import Layout from './components/Layout';
import ProductDetails from './components/ProductDetails';
import Privacy from './components/Privacy';

function App() {
  return (      
      <BrowserRouter>
        {/* <Helmet>     
          <script src="//widget.manychat.com/349080_02601.js" defer="defer"></script>
          <script src="https://mccdn.me/assets/js/widget.js" defer="defer"></script>
        </Helmet> */}
        <Layout>        
          <Switch>
            <Route path="/" exact component={Products} />       
            <Route path="/create" component={CreateProducts} />
            <Route path="/login" component={Login} />
            <Route path="/list" component={List} />
            <Route path="/contact" component={Contact} />      
            <Route path="/productDetails" component={ProductDetails} /> 
            <Route path="/privacy" component={Privacy} /> 
            <Route component={NotFound} />
          </Switch>         
        </Layout>
      </BrowserRouter>    
  );
}

export default App;
