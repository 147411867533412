import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "universal-cookie";
import Tracking from "./Tracking";

const cookies = new Cookies();
const tracking = new Tracking();

class CloseSession extends Component {
  closeSession = async () => {
    await tracking.TrackingUser("Cierre de sesión");
    cookies.remove("id", { path: "/" });
    cookies.remove("username", { path: "/" });
    window.location.href = "./";
  };

  render() {
    return (
      <div className="flex-row-item">
        <button
          className="btn btn-outline-danger btn-rounded"
          onClick={() => this.closeSession()}
        >
          Cerrar Sesión
        </button>
      </div>
    );
  }
}

export default CloseSession;
