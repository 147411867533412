import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import Cookies from "universal-cookie";
import Tracking from "./Tracking";
import PageError from "./Error";

import { Container } from "reactstrap";

const cookies = new Cookies();
const tracking = new Tracking();
const extra = process.env.REACT_APP_EXTRA;
const apiUrl = process.env.REACT_APP_API_SOURCE;

export default function Login() {
  const [form, setForm] = useState({ username: "", password: "" });
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);

  async function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if (token) {
      if (cookies.get("username")) {
        (async () => {
          await tracking.TrackingAdmin();
        })();
        window.location.href = "./list";
      } else {
        (async () => {
          await tracking.TrackingUser("Entró a login");
        })();
      }
    }
  }, [token]);

  useEffect(() => {
    (async () => {
      getToken();
    })();
  }, []);

  async function iniciarSesion() {
    await axios({
      method: "POST",
      url: apiUrl + "users",
      data: {
        username: form.username,
        password: form.password,
      },
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${token}`,
      },
    })
      .then((response) => {
        cookies.set("id", response.data._id, { path: "/", sameSite: "lax" });
        cookies.set("username", response.data.username, {
          path: "/",
          sameSite: "lax",
        });
        window.location.href = "./list";
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setError(err.response.data);
        }
      });
  }

  async function getToken() {
    const url = apiUrl + "app";
    axios({
      method: "POST",
      url: url,
      headers: {
        Extra: `${extra}`,
      },
    }).then((res) => {
      (async () => {
        setToken(res.data.token);
      })();
    });
  }

  return (
    <React.Fragment>
      {error ? <PageError error={error} /> : null}
      <Container>
        <div className="containerPrincipal">
          <div className="containerSecundario">
            <div className="form-group">
              <label>Usuario: </label>
              <br />
              <input
                type="text"
                className="form-control"
                name="username"
                onChange={(e) => handleChange(e)}
              />
              <br />
              <label>Contraseña: </label>
              <br />
              <input
                type="password"
                className="form-control"
                name="password"
                onChange={(e) => handleChange(e)}
              />
              <br />
              <br />
              <button
                className="btn btn-primary"
                onClick={() => iniciarSesion()}
              >
                Iniciar Sesión
              </button>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
