import React, { useState, useEffect } from "react";
import axios from "axios";
import Tracking from "./Tracking";
import Cookies from "universal-cookie";

import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

const extra = process.env.REACT_APP_EXTRA;
const apiUrl = process.env.REACT_APP_API_SOURCE;
const tracking = new Tracking();
const cookies = new Cookies();

export default function Contact() {
  const [token, setToken] = useState(null);
  const [modalMessage, setModalMessage] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    ip: "",
  });

  useEffect(() => {
    (async () => {
      await tracking.TrackingUser("Entra a Contacto");
      if (!token) {
        await getToken();
      }
    })();
  }, [token]);

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function onSubmit(e) {
    e.preventDefault();

    if (!cookies.get("address")) {
      await tracking.getIP();
    }

    form.ip = cookies.get("address");

    await sendData();
    await tracking.TrackingUser("Envía mensaje");
    await tracking.TrackingUser("Sale de Contacto");
    setModalMessage(true);
  }

  async function sendData() {
    let url = process.env.REACT_APP_API_SOURCE + "Messages/";
    await axios({
      method: "POST",
      url: url,
      data: {
        name: form.name,
        email: form.email,
        phone: form.phone,
        message: form.message,
        geolocation: form.ip,
      },
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${token}`,
      },
    })
      .then(async (res) => {
        
      })
      .catch((err) => console.log(err));
  }

  async function getToken() {
    const url = apiUrl + "app";
    await axios({
      method: "POST",
      url: url,
      headers: {
        Extra: `${extra}`,
      },
    }).then((res) => {
      setToken(res.data.token);
      window.token = res.data.token;
    });
  }

  function closeMessage() {
    setModalMessage(false);
    window.location = "./";
  }

  return (
    <React.Fragment>
      <div>
        <form onSubmit={(e) => onSubmit(e)}>
          <Container>
            <FormGroup>
              <label>Nombre: </label>
              <input
                type="text"
                required
                className="form-control"
                name="name"
                onChange={(e) => handleChange(e)}
              />
            </FormGroup>

            <FormGroup>
              <label>Email: </label>
              <input
                type="email"
                required
                className="form-control"
                name="email"
                onChange={(e) => handleChange(e)}
              />
            </FormGroup>

            <FormGroup>
              <label>Teléfono: </label>
              <input
                type="phone"
                required
                className="form-control"
                name="phone"
                onChange={(e) => handleChange(e)}
              />
            </FormGroup>

            <FormGroup>
              <label>Mensaje: </label>
              <textarea
                type="text"
                cols="40"
                rows="5"
                required
                className="form-control"
                name="message"
                onChange={(e) => handleChange(e)}
              />
            </FormGroup>

            <FormGroup>
              <input
                type="submit"
                value="Enviar mensaje"
                className="btn btn-primary"
              />{" "}
              <input
                type="submit"
                value="Regresar"
                className="btn btn-secondary"
              />
            </FormGroup>
          </Container>
        </form>
      </div>
      <Modal isOpen={modalMessage} size="lg">
        <ModalHeader>
          <div className="products" style={{ marginTop: "30px" }}></div>
          <div>
            <h3>Aviso</h3>
          </div>
        </ModalHeader>

        <ModalBody>
          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "stretch" }}
          >
            <FormGroup style={{ flexBasis: "100%" }}>
              <label>¡Mensaje recibido correctamente!</label>
            </FormGroup>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => closeMessage()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
