import React from "react";
import Cookies from "universal-cookie";
import CloseSession from "./CloseSession";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import degarg from "../assets/degarg.png";

const iconMessage = <FontAwesomeIcon icon={faTelegram} />;

const cookies = new Cookies();

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ip: "",
    };
  }

  render() {
    return (
      <footer className="footer-distributed">
        <div className="footer-left">
          <h3>
            Ciry's<span>Bazar</span>
          </h3>         
        </div>

        <div className="footer-center">
          <div className="footer-icons">
            {cookies.get("username") ? <CloseSession /> : null}
            <br />
            <Link to="/contact">{iconMessage}</Link>
          </div>
        </div>

        <div className="footer-right">		
		<Link to="/privacy" style={{ all: "unset", cursor: "pointer" }}>
            Política de privacidad
          </Link>
          <div className="footer-company-about">
            <div style={{ position: "relative", textAlign: "center" }}>
              <Link
                to={{ pathname: "https://www.degargstudio.com" }}
                target="_blank"
                style={{ all: "unset", cursor: "pointer" }}
              >
                <img
                  style={{
                    width: "100px",
                    position: "absolute",
                    top: "125%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  src={degarg}
                  alt="DEGARG Studio"
                />
                <p>
                  <span>
                    Develop by DEGARG Studio © {new Date().getFullYear()}
                  </span>
                </p>
              </Link>
            </div>
            <br />			
          </div>
		 
        </div>
      </footer>
    );
  }
}

export default Footer;
