import React from "react";
import { Link } from "react-router-dom";

class Navbar extends React.Component {
  render() {
    return (
      <nav className="navPrincipal">
        <ul>
          <li className="left">
            <Link to="/">CIRY'S BAZAR</Link>
          </li>
          <li className="right"></li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;
