import React, { useState, useEffect } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import Tracking from "./Tracking";

import "./Styles/Details.css";

export default function ProductDetails() {
  const location = useLocation();
  const history = useHistory();

  const tracking = new Tracking();

  const data = location.state.data;
  const similar = location.state.similar;
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [items, setItems] = useState([{ src: data.image }]);

  useEffect(() => {
    const listImages = data.images;
    setItems([{ src: data.image }]);

    listImages.forEach((element) => {
      let row = { src: element };
      setItems((items) => [...items, row]);
    });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [data]);

  function next() {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  function previous() {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  function goToIndex(newIndex) {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} className="img-details" />
      </CarouselItem>
    );
  });

  async function editar(dato) {
    await tracking.TrackingProduct(dato._id);
    await tracking.TrackingUser(
      "Descripcion producto: " + dato._id + "|" + dato.name
    );

    history.push("/productDetails", { data: dato, similar: similar });
  }

  return (
    <React.Fragment>
      <h2 className="product-title">
        {" "}
        <i className="fa fa-fw fa-arrow-right"></i> {data.name}
      </h2>
      <div
        className="container flex-row-container2"
        style={{ display: "flex" }}
      >
        <div className="carousel-content">
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Carousel>
        </div>

        <div className="product-content">
          <div className="product-title">
            <div className="flex-row-item">
              <span className="proPrice">$ {data.price}.00</span>
            </div>
          </div>

          <div className="product-detail">
            <p>{data.description}</p>
            <ul>
              <li>
                <i className="fa fa-fw fa-tags"></i>{" "}
                <span>{data.category}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container" style={{ alignContent: "center" }}>
        <h4 style={{ paddingTop: "20px" }}>Productos relacionados:</h4>

        <div
          className="container product-content"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {similar.map((el, index) => (
            <div key={index} style={{ padding: "10px" }}>
              <div className="img-carousel">
                <img
                  src={el.image}
                  key={index}
                  height="120px"
                  style={{ cursor: "pointer" }}
                  alt="Not found"
                  onClick={() => editar(el)}
                />
              </div>
              <div style={{ fontSize: "12px", textAlign: "center" }}>
                {el.name}
              </div>
              <div style={{ fontSize: "12px", textAlign: "center" }}>
                {" "}
                <span className="product-price">$ {el.price}.00</span>{" "}
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}
