import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "./Loading";
import PageError from "./Error";
import GoTop from "./GoTop";
import Tracking from "./Tracking";
import LargeImage from "./largeImage";
import FacebookTab from "./FacebookTab";
import ReactPaginate from "react-paginate";

const tracking = new Tracking();
const extra = process.env.REACT_APP_EXTRA;
const apiUrl = process.env.REACT_APP_API_SOURCE;

export default function Products(props) {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [lrgImg, setLrgImg] = useState({});
  const [modal, setModal] = useState({});
  const [form, setForm] = useState({ search: "", orderBy: "", categoryBy: "" });
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(16);

  useEffect(() => {
    async function getToken() {
      const url = apiUrl + "app";
      await axios({
        method: "POST",
        url: url,
        headers: {
          Extra: `${extra}`,
        },
      }).then((res) => {
        setToken(res.data.token);
        window.token = res.data.token;
      });
    }

    async function getProducts() {
      if (token) {
        let url = apiUrl + "Products/1/";
        await axios({
          method: "POST",
          url: url,
          headers: {
            "x-access-token": `${token}`,
          },
          params: {
            search: form.search,
            orderBy: form.orderBy,
            categoryBy: form.categoryBy,
          },
        }).then(
          (result) => {
            setProducts(result.data.data);
            setLimit(result.data.limit);
            setPageCount(Math.ceil(result.data.total / limit));
          },
          (err) => {
            setError(err);
          }
        );
      }
    }

    async function getCategories() {
      if (token) {
        let url = apiUrl + "Categories";

        axios({
          method: "GET",
          url: url,
          headers: {
            "x-access-token": `${token}`,
          },
        }).then(
          (result) => {
            setCategories(result.data);
          },
          (err) => {
            setError(err);
          }
        );
      }
    }

    try {
      if (!token) {
        (async () => {
          await getToken();
          await getCategories();
          await getProducts();
          setLoading(false);
        })();
      } else {
        getCategories();
        getProducts();
        setLoading(false);
      }
    } catch (err) {
      setError(err);
    }
  }, [token, limit, form]);

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });

    let filtering = "";
    if (form.orderBy) {
      filtering += "OrderBy: " + form.orderBy + "|";
    }

    if (form.categoryBy) {
      filtering += "CategoryBy: " + form.categoryBy + "|";
    }

    if (form.search) {
      filtering += "SearchBy: " + form.search;
    }

    tracking.TrackingUser(filtering);
  }

  function handleChangeImg(e) {
    e.preventDefault();
    const id = e.target.name.substring(3);
    const src = e.target.src;

    setLrgImg({ src: src });
    setModal({ display: "block" });

    tracking.TrackingProduct(id);
    tracking.TrackingUser("Imagen producto: " + id);
  }

  function handleCloseImg(e) {
    e.preventDefault();

    setLrgImg({ src: "" });
    setModal({ display: "none" });
  }

  function changeSimilar(dato) {
    tracking.TrackingProduct(dato._id);
    tracking.TrackingUser(
      "Descripcion producto: " + dato._id + "|" + dato.name
    );

    const likeProducts = products
      .filter((product) =>
        product.category.toLowerCase().includes(dato.category.toLowerCase())
      )
      .slice(0, 5);

    props.history.push("/productDetails", {
      data: dato,
      similar: likeProducts,
    });
  }

  async function fetchProducts(currentPage) {
    if (token) {
      let url = apiUrl + `Products/${currentPage}/`;

      await axios({
        method: "POST",
        url: url,
        headers: {
          "x-access-token": `${token}`,
        },
        params: {
          search: form.search,
          orderBy: form.orderBy,
          categoryBy: form.categoryBy,
        },
      }).then(
        (result) => {
          setProducts(result.data.data);
          setLimit(result.data.limit);
          setPageCount(Math.ceil(result.data.total / limit));
        },
        (err) => {
          setError(err);
        }
      );
    }
  }

  async function handlePageClick(data) {
    let currentPage = data.selected + 1;
    await fetchProducts(currentPage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <React.Fragment>
      {loading ? <Loading /> : null}
      {error ? <PageError error={error} /> : null}
      <GoTop />
      <FacebookTab />

      <div className="modal-largeImage" style={modal}>
        <LargeImage close={(e) => handleCloseImg(e)} src={lrgImg.src} />
      </div>

      <div className="container">
        <div className="products" style={{ marginTop: "10px" }}></div>
        <div className="flex-row-container">
          <div className="flex-row-item">
            <label htmlFor="categoryBy">
              <i className="fa fa-fw fa-list"></i>
            </label>
            <select
              name="categoryBy"
              id="categoryBy"
              placeholder="Seleccionar"
              onChange={(e) => handleChange(e)}
            >
              {categories.map((item) => (
                <option key={item}>{item}</option>
              ))}
            </select>
          </div>
          <div className="flex-row-item">
            <label htmlFor="orderBy">
              <i className="fa fa-fw fa-sort"></i>
            </label>
            <select
              name="orderBy"
              id="orderBy"
              placeholder="Seleccionar"
              onChange={(e) => handleChange(e)}
            >
              <option value="category">Categoría</option>
              <option value="price">Precio</option>
              <option value="name">Nombre</option>
            </select>
          </div>
          <div className="flex-row-item">
            <i className="fa fa-fw fa-search"></i>
            <input
              className="searchInput"
              type="text"
              placeholder="Buscar"
              name="search"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="products">
        {products.map((product) => (
          <div className="product" key={product._id}>
            <div className="productImage">
              <img
                className="img"
                name={"img" + product._id}
                key={product.image}
                src={product.image}
                onClick={(e) => handleChangeImg(e)}
                alt={product.name}
              />
            </div>
            <div className="productDetails">
              <div className="proName">{product.name}</div>

              <div className="proPrice">${product.price}.00</div>

              <div name={product._id} className="proDescription">
                {product.description}
              </div>
              <p
                name={"p" + product._id}
                onClick={() => changeSimilar(product)}
                className="LeerMas"
              >
                <i className="fa fa-fw fa-chevron-circle-down"></i> Leer más...
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="container">
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={(e) => handlePageClick(e)}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </React.Fragment>
  );
}
