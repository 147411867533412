import React from "react";

const Banner = () => {
  return (
    <div className="container">
      <header>
        {/* <div className="headerText">
          <div>
            <h1 className="header">CIRY'S BAZAR</h1>
            <p className="header">¡Bienvenid@s al catálogo digital!</p>
          </div>
        </div> */}
      </header>
    </div>
  );
};

export default Banner;
