import React from "react";

import "./Styles/Error.css";

function PageError(props) {
  return (
    <React.Fragment>
      <div className="error"></div>
      <div className="PageError">{props.error.message}</div>;
    </React.Fragment>
  );
}

export default PageError;
