import React, { Component } from "react";
import Tracking from './Tracking';

import 'bootstrap/dist/css/bootstrap.min.css';
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const iconFacebook = <FontAwesomeIcon icon={faFacebook} />
const tracking = new Tracking();

export default class GoTop extends Component {

    handleFacebook=async()=>{ 
        await tracking.TrackingUser('Salio a Facebook');
    }

    render() {        
        return (
            <div className="facebook-right-top show-back-to-top">               
                <div className="top">
                    <a target="_blank" onClick={this.handleFacebook} rel="noopener noreferrer" href="https://www.facebook.com/ciryscirysbazar">{iconFacebook}</a>                                            
                </div>             
            </div>
        );
    }
}