import axios from "axios";
import Cookies from "universal-cookie";
import { publicIpv4 } from "public-ip";

const cookies = new Cookies();
const apiUrl = process.env.REACT_APP_API_SOURCE;
const extra = process.env.REACT_APP_EXTRA;
let token = "";
let ip = "";

class Tracking {
  getToken = async () => {
    const url = apiUrl + "app";
    await axios({
      method: "POST",
      url: url,
      headers: {
        Extra: `${extra}`,
      },
    }).then((res) => {
      token = res.data.token;
      window.token = res.data.token;
    });
  };

  getIP = async () => {
    if (!cookies.get("address")) {
      ip = await publicIpv4({
        fallbackUrls: ["https://ipapi.co/json/"],
      });
      cookies.set("address", ip, { path: "/", sameSite: "lax" });
    } else {
      ip = cookies.get("address");
    }
  };

  TrackingUser = async (action) => {
    if (!token) {
      await this.getToken();
    }

    await this.getIP();

    await axios({
      method: "POST",
      url: apiUrl + "TrackingUser",
      data: {
        ip: ip,
        access: [{ action: action }],
      },
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${token}`,
      },
    }).catch((error) => {
      if (error.response.status === 400) {
        console.log(error.response.data);
      }
    });
  };

  TrackingAdmin = async () => {
    if (!token) {
      await this.getToken();
    }

    this.getIP();

    await axios({
      method: "POST",
      url: apiUrl + "trackingAdmin",
      data: {
        ip: ip,
      },
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${token}`,
      },
    }).catch((error) => {
      if (error.response.status === 400) {
        console.log(error.response.data);
      }
    });
  };

  TrackingProduct = async (id) => {
    this.getIP();
    await axios({
      method: "POST",
      url: apiUrl + "Tracking",
      data: {
        product: id,
        access: [{ ip: ip }],
      },
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${token}`,
      },
    }).catch((error) => {
      if (error.response.status === 400) {
        console.log(error.response.data);
      }
    });
  };
}

export default Tracking;
