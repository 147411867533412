import React from 'react';


const LargeImage = (props)=> {  
  return ( 
    <div className="modal-largeImage-content">  
      <div className="modal-largeImage-div">
        <img onClick={props.close} src={props.src} alt=""  />  
      </div>
    </div>        
  )
}

export default LargeImage;