import React from "react";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

export default function Privacy() {
  return (
    <React.Fragment>
      <div className="container">
        <p className="text-center">
          <h3>Política de privacidad </h3>
        </p>
        <p class="text-justify">
          El sitio web www.cirysbazar.click es propiedad de cirysbazar, que es
          un controlador de datos de tus datos personales.
        </p>
        <p class="text-justify">
          Hemos adoptado esta Política de privacidad, que determina cómo
          procesamos la información recopilada por www.cirysbazar.click, que
          también proporciona las razones por las que debemos recopilar ciertos
          datos personales sobre ti. Por lo tanto, debes leer esta Política de
          privacidad antes de usar el sitio web de www.cirysbazar.click.
        </p>
        <p class="text-justify">
          Cuidamos tus datos personales y nos comprometemos a garantizar su
          confidencialidad y seguridad.
        </p>
        <p className="text-center">
          <h5>Información personal que recopilamos:</h5>
        </p>
        <p class="text-justify">
          Cuando visitas www.cirysbazar.click, recopilamos automáticamente
          cierta información sobre tu dispositivo, incluida información sobre tu
          navegador web, dirección IP, zona horaria y algunas de las cookies
          instaladas en tu dispositivo. Además, a medida que navegas por el
          sitio, recopilamos información sobre las páginas web individuales o
          los productos que ves, qué sitios web o términos de búsqueda te
          remitieron al sitio y cómo interactúas con él. Nos referimos a esta
          información recopilada automáticamente como "Información del
          dispositivo". Además, podemos recopilar los datos personales que nos
          proporcionas (incluidos, entre otros, nombre, apellido, dirección,
          información de pago, etc.) durante el registro para poder cumplir con
          el acuerdo.
        </p>
        <p className="text-center">
          <h5> ¿Por qué procesamos tus datos?</h5>
        </p>
        <p class="text-justify">
          Nuestra máxima prioridad es la seguridad de los datos del cliente y,
          como tal, podemos procesar solo los datos mínimos del usuario, solo en
          la medida en que sea absolutamente necesario para mantener el sitio
          web. La información recopilada automáticamente se utiliza solo para
          identificar casos potenciales de abuso y establecer información
          estadística sobre el uso del sitio web. Esta información estadística
          no se agrega de tal manera que identifique a ningún usuario en
          particular del sistema.
        </p>
        <p class="text-justify">
          Puedes visitar el sitio sin decirnos quién eres ni revelar ninguna
          información por la cual alguien pueda identificarte como una persona
          específica. Sin embargo, si deseas utilizar algunas de las funciones
          del sitio web, o deseas recibir nuestro boletín informativo o
          proporcionar otros detalles al completar un formulario, puedes
          proporcionarnos datos personales, como tu correo electrónico, nombre,
          apellido, ciudad de residencia, organización y número de teléfono.
          Puedes optar por no proporcionar tus datos personales, pero es posible
          que no puedas aprovechar algunas de las funciones del sitio web. Por
          ejemplo, no podrás recibir nuestro boletín ni contactarnos
          directamente desde el sitio web.
        </p>
        <p className="text-center">
          <h5>Tus derechos:</h5>
        </p>
        <p class="text-justify">
          Si eres residente europeo, tienes los siguientes derechos relacionados
          con tus datos personales:
        </p>
        <p class="text-justify">
          <ul>El derecho a ser informado.</ul>
          <ul>El derecho de acceso.</ul>
          <ul>El derecho a la rectificación.</ul>
          <ul>El derecho a borrar.</ul>
          <ul>El derecho a restringir el procesamiento.</ul>
          <ul>El derecho a la portabilidad de datos.</ul>
          <ul>El derecho a oponerte.</ul>
          <ul>
            Derechos en relación con la toma de decisiones automatizada y la
            elaboración de perfiles.
          </ul>
        </p>
        <p class="text-justify">
          Si deseas ejercer este derecho, comunícate con nosotros a través de la
          información de contacto a continuación.
        </p>
        <p class="text-justify">
          Además, si eres residente europeo, destacamos que estamos procesando
          tu información para cumplir con los contratos que podríamos tener
          contigo (por ejemplo, si realizas un pedido a través del sitio), o de
          otra manera para seguir nuestros intereses comerciales legítimos
          enumerados anteriormente. Además, ten en cuenta que tu información
          puede transferirse fuera de Europa, incluidos Canadá y Estados Unidos.
        </p>
        <p class="text-center">
          <h5>Enlaces a otros sitios web:</h5>
        </p>
        <p class="text-justify">
          Nuestro sitio puede contener enlaces a otros sitios web que no son de
          nuestra propiedad ni están controlados por nosotros. Ten en cuenta que
          no somos responsables de dichos sitios web ni de las prácticas de
          privacidad de terceros. Te recomendamos que estés atento cuando
          abandones nuestro sitio web y leas las declaraciones de privacidad de
          cada sitio que pueda recopilar información personal.
        </p>
        <p class="text-center">
          <h5>Seguridad de la información:</h5>
        </p>
        <p class="text-justify">
          Aseguramos la información que proporcionas en servidores informáticos
          en un entorno controlado y seguro, protegido del acceso, uso o
          divulgación no autorizados. Mantenemos medidas de seguridad
          administrativas, técnicas y físicas razonables para proteger contra el
          acceso no autorizado, el uso, la modificación y la divulgación de
          datos personales bajo su control y custodia. Sin embargo, no se puede
          garantizar la transmisión de datos a través de Internet o redes
          inalámbricas.
        </p>
        <p class="text-center">
          <h5>Divulgación legal:</h5>
        </p>
        <p class="text-justify">
          Divulgaremos cualquier información que recopilemos, usemos o recibamos
          si así lo requiere o lo permite la ley, como para cumplir con una
          citación o un proceso legal similar, y cuando creemos de buena fe que
          la divulgación es necesaria para proteger nuestros derechos, proteger
          tu seguridad o la seguridad de los demás, investigar el fraude o
          responder a una solicitud del gobierno.
        </p>
        <p class="text-center">
          <h5>Información de contacto:</h5>
        </p>
        <p class="text-justify">
          Si deseas comunicarte con nosotros para comprender más sobre esta
          Política o deseas comunicarte con nosotros en relación con cualquier
          asunto sobre los derechos individuales y tu información personal,
          puedes dejarnos un mensaje en https://cirysbazar.click/contact.
        </p>
        <div className="text-center">
          <Link to="/">Regresar</Link>
        </div>
        <br />
      </div>
    </React.Fragment>
  );
}
