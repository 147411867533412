import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';


import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  FormGroup,
  Button,
} from "reactstrap";

const cookies = new Cookies();

export default class CreateProduct extends Component {
  constructor(props) {
    super(props);

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangePrice = this.onChangePrice.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.onChangeCategory = this.onChangeCategory.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.uploadImage = this.uploadImage.bind(this);

    this.state = {
      name: '',
      description: '',
      category: '',
      price: 0,
      Image: ''
    }
  }

  async uploadImage(image){
    let filename = ''
    let body = new FormData();
    body.set('key', '09b15ded7c0bc46427914b61799dee39');
    body.append('image', image);
  
    await axios({
      method: 'post',
      url: 'https://api.imgbb.com/1/upload',
      data: body
    }).then(res => {
      console.log('Response from main API: ',res)
      filename = res.data.data.url;      
    });
    return filename;
  }

  componentDidMount() {
    
    axios.get(process.env.REACT_APP_API_SOURCE + 'Products/')
      .then(response => {
        if (response.data.length > 0) {
          this.setState({
            users: response.data.map(user => user.username),
            username: response.data[0].username
          })
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    })
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value
    })
  }

  onChangeCategory(e) {
    this.setState({
      category: e.target.value
    })
  }

  onChangePrice(e) {
    this.setState({
      price: e.target.value
    })
  }

  onChangeImage(e) {
    this.setState({
      image: e.target.files[0]
    })
  }

  onCancel = () => {
    window.location = './list';   
  };

  onSubmit = async e => {
    e.preventDefault();

    let filename = await this.uploadImage(this.state.image);

    let url = process.env.REACT_APP_API_SOURCE + 'Products/';
    await axios({
      method: "POST",
      url: url,
      data: {
        name: this.state.name,
        description: this.state.description,
        category: this.state.category,
        price: this.state.price,
        image: filename,
      }, 
      headers: {'Content-Type' : 'application/json'}
    }).then(res => {
         //console.log(res.data);
         window.location = './list';
       }).catch(err => console.log(err))    
  }

  render() {
    if(!cookies.get('username')){
      window.location.href="./login"; 
    } else {
      return (  
        <React.Fragment>
          <Container>
             <form onSubmit={this.onSubmit}>
            <Container>
            
              <FormGroup>
                  <label>Nombre: </label>
                  <input  type="text"
                      required
                      className="form-control"
                      value={this.state.name}
                      onChange={this.onChangeName}
                      />
              </FormGroup>
              
              <FormGroup>
                  <label>Descripción: </label>
                  <textarea  type="text"
                  cols="40" rows="5"
                      required
                      className="form-control"
                      value={this.state.description}
                      onChange={this.onChangeDescription}
                      />
                </FormGroup>

                <FormGroup>
                  <label>Categoría: </label>
                  <input  type="text"
                      required
                      className="form-control"
                      value={this.state.category}
                      onChange={this.onChangeCategory}
                      />
              </FormGroup>
              

              <FormGroup>
                  <label>Precio: </label>
                  <input                  
                      type="text" 
                      required                               
                      className="form-control"
                      value={this.state.price}
                      onChange={this.onChangePrice}
                      />
                
                </FormGroup>

                <FormGroup>
                  <label>Imágen: </label>
                  <br />
                  <input type="file"
                          id="image"
                          accept="image/png, image/jpeg"  onChange={this.onChangeImage} required/>
                </FormGroup>  

                <FormGroup>
                <div className="flex-row-container"> 
                  <div className="flex-row-item">
                    <input type="submit" value="Agregar artículo" className="btn btn-primary" />
                  </div>
                  <div className="flex-row-item">
                    <Button onClick={() => this.onCancel()}>
                      Cancelar
                    </Button>
                  </div>
                </div>
                </FormGroup>
            
              </Container>
            </form>
          </Container>        
        </React.Fragment>
      )
    }
  }
}